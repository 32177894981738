// @ts-strict-ignore
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AktionFinishedNotification } from '@shared/models/signalR/aktionFinishedNotification';
import { WahlvorbereitungNotification } from '@shared/models/wahlvorbereitungInfo';
import { Observable, Subject } from 'rxjs';

export class SignalRConnectionInfo {
    endpoint: string;
    accessToken: string;
    url: string;
}

interface NegotiateResult {
    url?: string;
    accessToken?: string;
}

/**
 * Komponenten und Services, welche auf Nachrichten subscriben müssen sich selbst um den Nachrichteninhalt kümmern
 */
@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private WAHLVORBEREITUNG_MESSAGE: string = 'wahlvorbereitungMessage';
    private AGWR_ADRESSIMPORT_MESSAGE: string = 'agwrAdressimportMessage';
    private AKTION_FINISHED_MESSAGE: string = 'aktionFinishedMessage';
    private hubConnection: HubConnection;

    private wahlvorbereitungNotificationSubject: Subject<WahlvorbereitungNotification> =
        new Subject<WahlvorbereitungNotification>();
    wahlvorbereitungNotification$ = this.wahlvorbereitungNotificationSubject.asObservable();

    // AgwrStatusNachricht wird möglicherweise nicht benötigt
    private adressenNotificationSubject: Subject<string> = new Subject<string>();
    adressenNotification$ = this.adressenNotificationSubject.asObservable();

    private aktionNotificationSubject: Subject<AktionFinishedNotification> = new Subject<AktionFinishedNotification>();
    aktionNotfication$ = this.aktionNotificationSubject.asObservable();

    sigRAccessToken: string;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService,
        private alertService: AlertService
    ) {}

    /**
     * Öffnet die Verbindung zum SignalR-Service.
     * Da dieser serverless betrieben wird, muss für den Verbindungsaufbau die "negotiate"-Azure-Function
     * aufgerufen werden.
     */
    startConnection() {
        // Verbindungsoptionen aus dem Backend laden
        this.getSignalRConnection().subscribe({
            next: (negotiateResult: NegotiateResult) => {
                if (negotiateResult) {
                    // Verbindung zum SignalRServer herstellen
                    this.establishHubConnection(negotiateResult);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Verbindung mit dem SignalRService nicht möglich');
                return null;
            }
        });
    }

    /**
     * Stellt die Verbindung zum SignalRService und den Listener auf
     * Nachrichten für die Wahlvorbereitung her.
     * @param signalRConnection NegotiateResult
     */
    establishHubConnection(signalRConnection: NegotiateResult): void {
        // Verbindung aufbauen
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalRConnection.url, {
                // Der hier verwendete AccessToken kommt nicht vom IdentityProvider
                accessTokenFactory: () => signalRConnection.accessToken
            })
            .withAutomaticReconnect()
            .build();

        // Listener auf Wahlvorbereitungsnachrichten
        this.hubConnection.on(this.WAHLVORBEREITUNG_MESSAGE, (message: WahlvorbereitungNotification) => {
            this.wahlvorbereitungNotificationSubject.next(message);
        });

        // Listener auf AGWR Adressimportnachrichten
        this.hubConnection.on(this.AGWR_ADRESSIMPORT_MESSAGE, (message: string) => {
            this.adressenNotificationSubject.next(message);
        });

        // Listener auf Aktionnachrichten
        this.hubConnection.on(this.AKTION_FINISHED_MESSAGE, (message: AktionFinishedNotification) => {
            this.aktionNotificationSubject.next(message);
        });

        // Verbindung starten
        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection established');
            })
            .catch((err) => console.log('Error while starting connection: ' + err));
    }

    /**
     * Registriert sich auf die Benachrichtigungen der Wahlvorbereitung.
     * @returns Observable, das die Nachrichten repräsentiert.
     */
    addWahlvorbereitungNotificationListener(): Observable<WahlvorbereitungNotification> {
        return this.wahlvorbereitungNotificationSubject.asObservable();
    }

    /**
     * Schließt eine bestehende Verbindung zum SignalR Hub
     */
    closeHubConnection(): void {
        if (this.hubConnection) {
            this.hubConnection.stop();
            this.hubConnection = null;
        }
    }

    /**
     * Meldet den aktuellen Benutzer von den Benachrichtigungen ab
     * und schließt eine bestehende Verbindung zum SignalR Hub
     */
    removeUserAndCloseConnection(): void {
        this.removeUserFromGroup().subscribe({
            next: () => {
                // Aktuell keine Bearbeitung vorgesehen
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abmeldung der Benachrichtigungsgruppe');
            }
        });
        this.closeHubConnection();
    }

    /**
     * Entfernt den angemeldeten Benutzer aus einer Nachrichtengruppe des SignalR-Service
     * damit keine Nachrichten für diese mehr angezeigt werden.
     * z.B.: Mandantenwechsel
     * @returns Observable Fehler oder OK
     */
    removeUserFromGroup(): Observable<unknown> {
        return this.httpClient.delete(`${this.configService.getConfig().k5SignalR.signalrApiUrl}/negotiate`);
    }

    /**
     * Liefert die Einstellungen für die Verbindung des UI Clients
     * zum Azure SignalR-Service zurück.
     * Aufrufer benötigt Rechte um Einstellungen zu erhalten
     * @returns NegotiateResult Einstellungen für die Verbindung zum SignalR-Service
     */
    private getSignalRConnection(): Observable<NegotiateResult> {
        return this.httpClient.get<NegotiateResult>(
            `${this.configService.getConfig().k5SignalR.signalrApiUrl}/negotiate`
        );
    }
}
